{
  "name": "@geoman-io/leaflet-geoman-free",
  "version": "2.17.0",
  "description": "A Leaflet Plugin For Editing Geometry Layers in Leaflet 1.0",
  "keywords": [
    "leaflet",
    "geoman",
    "polygon management",
    "geometry editing",
    "map data",
    "map overlay",
    "polygon",
    "geojson",
    "leaflet-draw",
    "data-field-geojson",
    "ui-leaflet-draw"
  ],
  "files": [
    "dist"
  ],
  "main": "dist/leaflet-geoman.js",
  "types": "dist/leaflet-geoman.d.ts",
  "dependencies": {
    "@turf/boolean-contains": "^6.5.0",
    "@turf/kinks": "^6.5.0",
    "@turf/line-intersect": "^6.5.0",
    "@turf/line-split": "^6.5.0",
    "lodash": "4.17.21",
    "polyclip-ts": "^0.16.5"
  },
  "devDependencies": {
    "@types/leaflet": "^1.7.9",
    "cross-env": "^7.0.3",
    "cypress": "6.9.1",
    "cypress-wait-until": "1.7.1",
    "esbuild": "^0.20.0",
    "eslint": "8.56.0",
    "eslint-config-airbnb-base": "15.0.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "2.15.1",
    "eslint-plugin-import": "2.29.1",
    "husky": "^9.0.7",
    "leaflet": "1.9.3",
    "lint-staged": "^15.2.1",
    "prettier": "3.2.4",
    "prosthetic-hand": "1.3.1",
    "ts-node": "^10.9.2"
  },
  "peerDependencies": {
    "leaflet": "^1.2.0"
  },
  "scripts": {
    "start": "pnpm run dev",
    "dev": "cross-env DEV=true ts-node bundle.mjs",
    "build": "ts-node bundle.mjs",
    "test": "cypress run",
    "cypress": "cypress open",
    "prepare": "pnpm run build && husky",
    "eslint-check": "eslint --print-config . | eslint-config-prettier-check",
    "eslint": "eslint \"{src,cypress,demo}/**/*.js\" --fix ",
    "prettier": "prettier --write \"{src,cypress,demo}/**/*.{js,css}\" --log-level=warn",
    "lint": "pnpm run eslint && pnpm run prettier"
  },
  "repository": {
    "type": "git",
    "url": "git://github.com/geoman-io/leaflet-geoman.git"
  },
  "author": {
    "name": "Geoman.io",
    "email": "sales@geoman.io",
    "url": "http://geoman.io"
  },
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/geoman-io/leaflet-geoman/issues"
  },
  "homepage": "https://geoman.io",
  "prettier": {
    "trailingComma": "es5",
    "tabWidth": 2,
    "semi": true,
    "singleQuote": true
  },
  "lint-staged": {
    "*.js": "eslint \"{src,cypress,demo}/**/*.js\" --fix",
    "*.{js,css,md}": "prettier --write \"{src,cypress,demo}/**/*.{js,css}\""
  }
}
